<template>
  <NuxtLink
    no-prefetch
    :to="props?.button?.href"
    class="group relative block aspect-news-box w-full xxs:min-w-[16.25rem] xl:aspect-news-box-big"
    :class="classGradient"
  >
    <UiImage
      v-if="props.image?.src"
      :src="props.image?.src"
      width="308"
      height="569"
      loading="lazy"
      fetchpriority="low"
      class="size-full object-cover group-hover:mix-blend-multiply"
    />
    <div
      class="absolute inset-x-0 bottom-0 flex flex-col justify-between bg-white px-7 pb-7 pt-6 text-primary group-hover:bg-transparent"
    >
      <div
        :class="{
          'flex h-full flex-col justify-between xl:block':
            props.authorImage && !props.description,
        }"
      >
        <p
          v-if="props.header"
          class="h4 mb-4 line-clamp-3 h-[4.5rem] group-hover:text-white sm:h-[6.5rem]"
        >
          {{ props.header }}
        </p>
        <p
          v-if="props.description"
          class="line-clamp-2 text-base font-normal group-hover:text-white"
        >
          {{ props.description }}
        </p>
        <div
          v-if="props.authorFirstname && props.authorImage"
          class="flex"
        >
          <UiImage
            v-if="Object.keys(props.authorImage).length !== 0"
            :src="props.authorImage.src"
            :width="props.authorImage.width"
            :height="props.authorImage.height"
            loading="lazy"
            fetchpriority="low"
            class="mr-5 flex shrink-0"
          />
          <div class="text-black group-hover:text-white">
            <p>{{ props.authorFirstname }} {{ props.authorLastname }}</p>
            <p>{{ props.authorPosition }}</p>
          </div>
        </div>
      </div>
      <div class="mt-7 hidden xl:block">
        <UiButton
          v-if="props.button?.href"
          variant="circle"
          with-animation
          class="group-hover:border-white group-hover:text-white"
        >
          {{ props.button.title }}
        </UiButton>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiButton from '../UiButton/UiButton.vue'
import UiImage from '../UiImage/UiImage.vue'
import type { ImageInterface } from '../UiImage/UiImage.types'

interface UiButtonType {
  href: string
  target?: string | null
  title: string
}

interface UiBlogTeaser {
  header?: string
  description?: string
  image: ImageInterface | null
  button: UiButtonType
  authorImage?: ImageInterface | null
  authorFirstname?: string
  authorLastname?: string
  authorPosition?: string
  gradient?: string
}

const props = withDefaults(defineProps<UiBlogTeaser>(), {
  header: '',
  description: '',
  image: null,
  button: () => ({
    href: '',
    target: null,
    title: '',
  }),
  authorImage: null,
  authorFirstname: '',
  authorLastname: '',
  authorPosition: '',
  gradient: '',
})

const classGradient = computed(() => {
  switch (props.gradient) {
    case 'color-gradient-blue':
      return 'bg-gradient-bright-blue'
    case 'color-gray':
      return 'bg-grey-50'
    case 'color-gradient-green':
      return 'bg-gradient-green'
    case 'color-gradient-purple':
      return 'bg-gradient-purple'
    case 'color-light-blue':
      return 'bg-blue-200'
    default:
      return 'bg-primary'
  }
})
</script>
